<template>
  <section class="userPlatforms">
    <h2 class="userPlatforms__title">Plataformas</h2>
    <div class="userPlatforms__contentRoles">
      <li class="userPlatforms__checkbox" v-for="platform in streamingOptions" :key="platform.name">
        <input
          class="mycheck "
          type="checkbox"
          :id="platform.name"
          :value="platform.name"
          v-model="platform.isSelected"
          @change="checkPlatform(platform.name, platform.isSelected)"
        />
        <label :for="platform.name">{{ platform.name }}</label>
      </li>
    </div>
    <div class="userPlatforms__contentPlatform" v-for="option in dataOptions">
      <h2 :class="`userPlatforms__title userPlatforms__title--${option.name}`">{{ option.name }}</h2>
      <div class="userPlatforms__contentInput">
        <label :for="`email-${option.name}`">Correo *</label>
        <input
          class="userPlatforms__input"
          type="email"
          :id="`email-${option.name}`"
          :name="option.name"
          :ref="`email-${option.name}Form`"
          placeholder="Correo"
          v-model="option.email"
          @input="chageItem('email')"
          @blur="validateEmail(`email-${option.name}`, `email-${option.name}Form`)"
        />
      </div>
      <span :ref="`email-${option.name}`" class="userPlatforms__error"></span>
      <div class="userPlatforms__contentInput">
        <label :for="`password-${option.name}`">Contraseña</label>
        <input
          class="userPlatforms__input"
          type="password"
          :id="`password-${option.name}`"
          :name="option.name"
          :ref="`password-${option.name}Form`"
          placeholder="Contraseña"
          v-model="option.password"
          @input="chageItem('password')"
          @blur="validatePassword(`password-${option.name}`, `password-${option.name}Form`, 'la contraseña')"
        />
      </div>
      <span :ref="`password-${option.name}`" class="userPlatforms__error"></span>
    </div>
  </section>
</template>

<script>
import { formatEmail } from "@/js/validation.js";
export default {
  data() {
    return {
      streaming: ["Live Jasmin", "Streamate"],
      storeKeys: {
        "Live Jasmin": {
          email: "jasminUser",
          password: "jasminPassword",
        },
        Streamate: {
          email: "streamateUser",
          password: "streamatePassword",
        },
      },
      streamingOptions: [],
      platformSelected: null,
    };
  },
  computed: {
    user() {
      return this.$store.state.user.dataUser;
    },
    isEditUser() {
      return !!this.user?._id;
    },
    dataOptions() {
      const options = this.streamingOptions.filter((option) => option.name !== "Ambas" && option.isSelected);

      return options.map(({ name }) => {
        const email = this.$store.state.user.dataUser[this.storeKeys[name].email];
        const password = this.$store.state.user.dataUser[this.storeKeys[name].password];

        return { name, email, password };
      });
    },
  },
  methods: {
    chageItem(key) {
      const { name, value } = event.target;
      this.$store.state.user.dataUser[this.storeKeys[name][key]] = value;
    },
    createStreamingOptions() {
      const { dataUser } = this.$store.state.user;
      const allOptions = { name: "Ambas", isSelected: false };

      const options = this.streaming.map((name) => {
        const isSelected = !!dataUser[this.storeKeys?.[name]?.password] || !!dataUser[this.storeKeys?.[name]?.email];

        return { name, isSelected };
      });

      allOptions.isSelected = options.every(({ isSelected }) => isSelected);
      options.push(allOptions);

      return options;
    },
    resetKeysInStore(platform) {
      Object.values(this.storeKeys[platform]).forEach((val) => (this.$store.state.user.dataUser[val] = ""));
    },
    checkPlatform(platform, newValue) {
      if (platform === "Ambas") {
        this.streamingOptions.forEach((option) => (option.isSelected = newValue));

        if (!newValue) {
          this.resetKeysInStore("Live Jasmin");
          this.resetKeysInStore("Streamate");
        }
        return;
      }

      const allOption = this.streamingOptions.find((option) => option.name === "Ambas");
      allOption.isSelected = !this.streamingOptions.some((option) => !option.isSelected && option.name !== "Ambas");

      if (!newValue) {
        this.resetKeysInStore(platform);
      }
    },
    updateStyles(reference, referenceLocal, response) {
      reference.style.display = response ? "none" : "block";
      referenceLocal.style["border-color"] = response ? "#d8d8d8" : "#bd0909";
    },
    validateEmail(ref, refLocal, data) {
      const reference = this.$refs[ref][0];
      const referenceLocal = this.$refs[refLocal][0];
      const dataToSet = data ?? event?.srcElement ?? { value: "" };

      const response = formatEmail(reference, "", dataToSet);

      this.updateStyles(reference, referenceLocal, response);

      return response;
    },
    validatePassword(ref, refLocal, text, data) {
      const reference = this.$refs[ref][0];
      const referenceLocal = this.$refs[refLocal][0];
      const dataToSet = data ?? event?.srcElement?.value ?? "";

      const innerText = dataToSet === "" || dataToSet === "NA" ? `Ingrese ${text}` : "";
      const response = !innerText;

      reference.innerText = innerText;
      this.updateStyles(reference, referenceLocal, response);

      return response;
    },
    verifyNext() {
      const { jasminUser, streamateUser } = this.$store.state.user.dataUser;
      const jasmin = this.dataOptions.find(({ name }) => name === "Live Jasmin");
      const streamate = this.dataOptions.find(({ name }) => name === "Streamate");

      const verifyJasmin = !jasmin || this.validateEmail("email-Live Jasmin", "email-Live JasminForm", { value: jasminUser });
      const verifyStreamate = !streamate || this.validateEmail("email-Streamate", "email-StreamateForm", { value: streamateUser });

      return verifyJasmin && verifyStreamate;
    },
  },
  beforeMount() {
    this.streamingOptions = this.createStreamingOptions();
  },
  mounted() {
    if (this.isEditUser) {
      this.verifyNext();
    }
  },
};
</script>

<style lang="scss">
.userPlatforms {
  @include Flex(column, flex-start, flex-start);
  width: 100%;
  gap: $mpadding;
  &__title {
    font-weight: lighter;
    font-size: 24px;
    &--Streamate {
      color: $matisse;
    }
  }
  &__contentRoles {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(130px, 1fr));
    width: 100%;
    gap: $mpadding / 2;
  }
  &__checkbox {
    @include Flex(row, flex-start);
    width: 100%;
    gap: $mpadding / 2;
    > label {
      cursor: pointer;
      text-transform: capitalize;
      user-select: none;
    }
  }
  .mycheck {
    width: 20px;
    height: 20px;
    padding: 5px;
    border-radius: 4px;
    &:checked {
      background-color: $primary-color;
      border-color: $primary-color;
    }
  }
  &__contentPlatform {
    @include Flex(column, flex-start, flex-start);
    width: 100%;
    gap: $mpadding / 1.5;
  }
  &__contentInput {
    width: 100%;
  }
  &__input {
    width: 100%;
    height: 40px;
    border: 1px solid $alto;
    border-radius: 6px;
    padding: 5px;
    &:focus {
      outline: none;
    }
  }
  &__error {
    display: none;
    margin-top: -10px;
    font-weight: 600;
    font-size: 0.8em;
    color: $primary-color;
  }
}
</style>
